import React, { useContext } from "react";
import Layout from "@components/MyLayout";
import { css } from "@emotion/react";
import Playlist from "@components/Playlist";
import Header from "@components/Header";
import Loader from "@components/Loader";
import { PlayContext } from "@providers/PlayProvider";
import { ACCENT } from "../styles/colors";
import { Helmet } from "react-helmet";
import image from "@assets/ruing-og-image.jpg";

const Instructions = () => {
  const { tracksLoaded } = useContext(PlayContext);
  return (
    <div
      css={css`
        position: absolute;
        top: calc(100% - 60px);
        left: 0;
        width: 100%;
        padding: 16px 16px;
        text-align: center;
        color: ${ACCENT};
        z-index: 75;
        font-size: 18px;
        opacity: ${tracksLoaded ? "1" : "0"};
        pointer-events: ${tracksLoaded ? "all" : "none"};
        transition-delay: 1s;

        @media (min-width: 768px) {
          font-size: 24px;
        }
      `}
    >
      <span
        css={css`
          margin-top: -45px;
          display: block;
          @media (min-width: 1200px) {
            display: none;
          }
        `}
      >
        touch to listen
      </span>
      <span
        css={css`
          margin-top: -60px;
          @media (max-width: 1199px) {
            display: none;
          }
        `}
      >
        move through fragments to listen
      </span>
    </div>
  );
};

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ruins — a sound installation by Chloe Knibbs</title>
        <meta
          name="description"
          content="Ruins explores the relationship between women composers’ work, its
        perceived value and the factors that lead to it being eroded from our
        cultural memory."
        />
        <meta name="author" content="Chloe Knibbs" />
        <meta name="image" content={image} />
        <link rel="canonical" href="https://ruins.chloeknibbs.com/" />
      </Helmet>
      <Header />
      <Playlist />
      <Instructions />
      <Loader />
      <canvas
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none;
          z-index: 60;
        `}
        id="glcanvas"
        width="100vw"
        height="100vh"
      ></canvas>
    </Layout>
  );
};

/*  <Playlist />
      <Instructions /> */

export default IndexPage;
