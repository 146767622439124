import React from "react";
import { css } from "@emotion/react";
import { ACCENT, BLACK } from "../styles/colors";

const close = `<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
        <g id="Desktop-HD-Copy" transform="translate(-1384.000000, -15.000000)" stroke="#B3551C" stroke-width="1.7">
            <g id="info">
                <g id="close-btn" transform="translate(1385.000000, 16.000000)">
                    <line x1="0.5" y1="0.5" x2="29.5" y2="29.5" id="Line-4"></line>
                    <line x1="0.5" y1="0.5" x2="29.5" y2="29.5" id="Line-4-Copy" transform="translate(15.000000, 15.000000) scale(-1, 1) translate(-15.000000, -15.000000) "></line>
                </g>
            </g>
        </g>
    </g>
</svg>`;

const enter = `<svg width="28px" height="21px" viewBox="0 0 28 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <rect id="path-1" x="0" y="0" width="13" height="17"></rect>
    </defs>
        <g id="Desktop-HD-Copy" transform="translate(-414.000000, -93.000000)">
            <g id="info">
                <g id="enter" transform="translate(415.000000, 94.000000)">
                    <g id="Oval" transform="translate(15.000000, 3.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlink:href="#path-1"></use>
                        </mask>
                        <g id="Mask"></g>
                        <path d="M0,28 C6.07513225,28 11,23.0751322 11,17 C11,10.9248678 6.07513225,6 0,6 C-6.07513225,6 -11,10.9248678 -11,17 C-11,23.0751322 -6.07513225,28 0,28 Z" stroke="#B3551C" stroke-width="1.7" mask="url(#mask-2)"></path>
                    </g>
                    <line x1="2" y1="9" x2="15" y2="9" id="Line-2" stroke="#B3551C" stroke-width="1.7" stroke-linecap="square"></line>
                    <polyline id="Line" stroke-width="1.7" transform="translate(9.000000, 9.000000) rotate(-45.000000) translate(-9.000000, -9.000000) " points="3 15 3 3 15 3"></polyline>
                </g>
            </g>
        </g>
</svg>`;

const icons = { enter, close };

const Icon = ({ name }) => {
  const icon = icons[name];
  return (
    <span
      css={css`
        svg {
          line,
          path,
          polyline {
            stroke: ${ACCENT};
            transition: 0.15s all;
            fill: none;
          }

          &:hover {
            line,
            path,
            polyline {
              stroke: ${BLACK};
            }
          }
        }
      `}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
};

export default Icon;
