import React, { useContext } from "react";
import { css } from "@emotion/react";
import { PlayContext } from "@providers/PlayProvider";
import ReactPlayer from "react-player";
import kng from "@assets/07-a-cry.mp4";

const Video = ({ src }) => {
  const { current } = useContext(PlayContext);

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <ReactPlayer
        muted={true}
        url={kng}
        playing={current && current.name === "A cry" ? true : false}
        width="100%"
        height="auto"
      />
    </div>
  );
};

export default Video;
