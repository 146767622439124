import React, { useState, useContext } from "react";
import { css } from "@emotion/react";
import { ACCENT } from "../styles/colors";
import InfoWindow from "./InfoWindow";
import { PlayContext } from "@providers/PlayProvider";
import { tracks } from "@providers/tracks";
import { findIndex } from "lodash";

const Header = () => {
  const [infoOpen, setInfoOpen] = useState(false);
  const { current, tracksLoaded } = useContext(PlayContext);

  const renderCurrentTrack = (track) => {
    const currentTrackIndex = findIndex(tracks, { name: track.name });
    //console.log("index", currentTrackIndex);
    const currentTrack = `${currentTrackIndex + 1}/10 ${track.name}`;
    return currentTrack;
  };

  return (
    <React.Fragment>
      <nav
        css={css`
          position: fixed;
          padding: 0px 12px;
          top: 0;
          left: 0;
          width: 100%;
          height: 48px;
          border-bottom: 1px solid ${ACCENT};
          display: flex;
          align-items: center;
          justify-content: space-between;
          z-index: 75;
          opacity: ${tracksLoaded ? "1" : "0"};
          pointer-events: ${tracksLoaded ? "all" : "none"};
          transition-delay: 1s;

          @media (min-width: 768px) {
            padding: 0px 20px;
            height: 66px;
          }
        `}
      >
        <h1
          css={css`
            font-family: "ogg-light", sans-serif;
            font-size: 48px;
            display: inline;
            margin: 0px;
            color: ${ACCENT};
            font-weight: normal;

            @media (min-width: 768px) {
              font-size: 60px;
            }
          `}
        >
          Ruins
        </h1>
        <span
          css={css`
            font-size: 18px;
            color: ${ACCENT};
            opacity: ${current ? "1" : "0"};
            transition: 0.1s all;

            @media (min-width: 768px) {
              font-size: 24px;
            }
          `}
        >
          {current && renderCurrentTrack(current)}
        </span>
        <button
          css={css`
            font-size: 18px;

            @media (min-width: 768px) {
              font-size: 24px;
            }
          `}
          onClick={() => {
            setInfoOpen(true);
          }}
        >
          info
        </button>
      </nav>
      <InfoWindow
        open={infoOpen}
        onCloseClick={() => {
          setInfoOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default Header;
