import React, { useContext, useEffect, useRef, createRef } from "react";
import { css } from "@emotion/react";
import { PlayContext } from "@providers/PlayProvider";
import { BACKGROUND } from "../styles/colors";
import gsap from "gsap";
import { forEach } from "lodash";
import { findIndex } from "lodash";
import { Track } from "./Track";

const Playlist = () => {
  const { tracks, tracksLoaded, current } = useContext(PlayContext);
  const trackRefs = useRef([]);
  trackRefs.current = Array(10)
    .fill()
    .map((_, i) => trackRefs.current[i] || createRef());

  useEffect(() => {
    animateTrack();
  }, [current]);
  /* 
} */
  const animateTrack = () => {
    let whichOne = "mobile";
    const currentTrackIndex = findIndex(tracks, current);
    if (typeof window === "object") {
      if (window.innerWidth < 768) {
        whichOne = "mobile";
      } else if (window.innerWidth < 992) {
        whichOne = "tablet";
      } else if (window.innerWidth < 1200) {
        whichOne = "tabletLandscape";
      } else {
        whichOne = "desktop";
      }
    }

    if (whichOne !== "desktop") {
      forEach(tracks, (track, index) => {
        if (current) {
          if (index !== currentTrackIndex) {
            gsap.to(trackRefs.current[index].current, {
              mixBlendMode: "multiply",
              opacity: 0,
              duration: 1,
              ease: "power2.easeInOut",
            });
          } else {
            gsap.to(trackRefs.current[index].current, {
              mixBlendMode: "multiply",
              duration: 1,
              ease: "power2.easeInOut",
              top: "50%",
              left: "50%",
              scale: 1.3,
            });
          }
        } else {
          gsap.to(trackRefs.current[index].current, {
            mixBlendMode: "multiply",
            opacity: 1,
            duration: 1,
            ease: "power2.easeInOut",
            top: `${track.asset.pos[whichOne].y}%`,
            left: `${track.asset.pos[whichOne].x}%`,
            scale: 1,
          });
        }
      });
    }
  };

  return (
    <div
      css={css`
        position: relative;
        height: 100vh;
        width: 100%;
        background: ${BACKGROUND};
        pointer-events: ${tracksLoaded ? "all" : "none"};
        //pointer-events: none;
      `}
    >
      {tracks &&
        tracks.map((track, index) => (
          <div
            key={`track--${index}`}
            ref={trackRefs.current[index]}
            css={css`
              position: absolute;
              transform: translate3d(-50%, -50%, 0);
              max-width: ${track.asset.pos.mobile.maxWidth}px;
              width: ${track.asset.pos.mobile.width}%;
              top: ${track.asset.pos.mobile.y}%;
              left: ${track.asset.pos.mobile.x}%;
              mix-blend-mode: multiply;
              cursor: pointer;

              ${index === 1 &&
              "min-width: " + track.asset.pos.mobile.width + "%;"}

              @media (min-width: 768px) {
                top: ${track.asset.pos.tablet.y}%;
                left: ${track.asset.pos.tablet.x}%;
                max-width: ${track.asset.pos.tablet.maxWidth}px;
                width: ${track.asset.pos.tablet.width}%;
                ${index === 1 &&
                "min-width: " + track.asset.pos.tablet.width + "%;"}
              }

              @media (min-width: 992px) {
                top: ${track.asset.pos.tabletLandscape.y}%;
                left: ${track.asset.pos.tabletLandscape.x}%;
                max-width: ${track.asset.pos.tabletLandscape.maxWidth}px;
                width: ${track.asset.pos.tabletLandscape.width}%;
                ${index === 1 &&
                "min-width: " + track.asset.pos.tabletLandscape.width + "%;"}
              }

              @media (min-width: 1200px) {
                top: ${track.asset.pos.desktop.y}%;
                left: ${track.asset.pos.desktop.x}%;
                max-width: ${track.asset.pos.desktop.maxWidth}px;
                width: ${track.asset.pos.desktop.width}%;
                ${index === 1 && "min-width: auto;"}
              }
            `}
          >
            <Track
              key={`track--${index}`}
              index={index}
              asset={track.asset}
              onClick={animateTrack}
            />
          </div>
        ))}
    </div>
  );
};
export default Playlist;
