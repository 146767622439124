import React, { useContext, useEffect, useRef, forwardRef } from "react";
import { css } from "@emotion/react";
import { PlayContext } from "@providers/PlayProvider";
import { findIndex } from "lodash";
import Video from "@components/Video";

export const Track = ({ index, asset, onClick }) => {
  const containerRef = useRef(null);
  const hammerRef = useRef(null);
  const {
    tracks,
    current,
    tracksLoaded,
    onTrackMouseout,
    onTrackMouseover,
  } = useContext(PlayContext);
  // index - 6 - video

  const isCurrent = findIndex(tracks, current) === index;
  const enable = current ? isCurrent : true;

  const initTouchEvents = () => {
    if (typeof window === "object") {
      const Hammer = require("hammerjs");
      hammerRef.current = new Hammer.Manager(containerRef.current, {});
      const longpress = new Hammer.Press({ time: 1000 });
      hammerRef.current.add(longpress);

      hammerRef.current.on("press", (e) => {
        if (!current) {
          onTrackMouseover(index);
        }
      });
    }
  };

  const addEventListeners = () => {
    const onMouseover = () => {
      onTrackMouseover(index);
    };
    const onMouseout = () => {
      onTrackMouseout(index);
    };
    if (tracksLoaded) {
      containerRef.current.addEventListener("mouseover", onMouseover);
      containerRef.current.addEventListener("mouseout", onMouseout);
    }
    return () => {
      containerRef.current.removeEventListener("mouseover", onMouseover);
      containerRef.current.removeEventListener("mouseout", onMouseout);
    };
  };

  useEffect(() => {
    initTouchEvents();
    addEventListeners();
  }, [tracksLoaded]);

  return (
    <div
      ref={containerRef}
      className="ruins-clickable"
      onClick={() => {
        onClick(index);
      }}
      css={css`
        background: transparent;
        @media (max-width: 1199px) {
          pointer-events: ${enable ? "all" : "none"};
        }
      `}
    >
      {index !== 6 && <img src={asset.src} />}
      {index === 6 && <Video src={asset.src} />}
    </div>
  );
};
