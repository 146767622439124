import React, { useContext, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { PlayContext } from "@providers/PlayProvider";
import { BACKGROUND, BLACK } from "../styles/colors";
import { gsap, ScrollTrigger } from "gsap/all";
import SplitText from "../plugins/SplitText";
gsap.registerPlugin(ScrollTrigger, SplitText);

const Loader = () => {
  const loaderRef = useRef(null);
  const titleRef = useRef(null);
  const splitRef = useRef(null);
  const exploreRef = useRef(null);
  const { loadTracks, tracksLoaded } = useContext(PlayContext);
  const anim = {
    from: {
      opacity: 0,
      rotateY: "90deg",
    },
    to: {
      opacity: 1,
      rotateY: "0deg",
    },
  };

  useEffect(() => {
    splitRef.current = new SplitText(titleRef.current, {
      type: ["words", "chars"],
    });
    gsap.set(splitRef.current.chars, {
      ...anim.from,
    });
    gsap.set(splitRef.current.words, {
      overflowY: "hidden",
      //height: 150,
    });
    gsap.set(titleRef.current, { opacity: 1 });
    gsap.set(exploreRef.current, {
      opacity: 0,
      pointerEvents: "none",
    });

    showTitle();
  });

  useEffect(() => {
    if (tracksLoaded) {
      hideLoader();
    }
  }, [tracksLoaded]);

  const showTitle = () => {
    gsap.to(splitRef.current.chars, {
      duration: 2,
      delay: 1,
      ...anim.to,
      ease: "power3.inOut",
      stagger: {
        amount: 0.65,
      },
      onComplete: () => {
        gsap.to(exploreRef.current, {
          opacity: 1,
          pointerEvents: "all",
          duration: 1,
        });
      },
    });
  };

  const hideLoader = () => {
    gsap.to(loaderRef.current, {
      opacity: "0",
      pointerEvents: "none",
      duration: 1,
      delay: 0.85,
    });
    gsap.to(exploreRef.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.25,
      onComplete: () => {
        const el = exploreRef.current;
        el.parentElement.removeChild(el);
      },
    });
  };

  return (
    <React.Fragment>
      <div
        ref={loaderRef}
        css={css`
          position: fixed;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          background: ${BLACK};
          z-index: 50;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          pointer-events: none;

          button {
          }
        `}
      >
        <h2
          ref={titleRef}
          css={css`
            line-height: 0.85em;
            font-size: 40vw;
            font-family: "ogg-light";
            color: ${BACKGROUND};
            font-weight: normal;
            opacity: 0;
          `}
        >
          Ruins
        </h2>
      </div>
      <button
        ref={exploreRef}
        onClick={loadTracks}
        css={css`
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          height: 90px;
          padding: 0px 90px;
          font-size: 30px;
          pointer-events: all;
          opacity: 0;
          z-index: 80;

          &:hover {
            color: ${BACKGROUND};
          }
        `}
      >
        explore
      </button>
    </React.Fragment>
  );
};
export default Loader;
