import React from "react";
import { css } from "@emotion/react";
import { ACCENT } from "../styles/colors";
import Icon from "@components/Icon";

const Link = ({ text, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      css={css`
        display: block;
        align-items: center;
        margin-bottom: 20px;

        &:hover {
          color: black;
          svg {
            path,
            polyline,
            line {
              stroke: black;
            }
          }
        }

        @media (max-width: 991px) {
          display: block;
        }

        span {
          margin-right: 10px;
          font-size: 34px;
          line-height: 34px;

          @media (min-width: 768px) {
            font-size: 44px;
            line-height: 44px;
          }
        }
      `}
    >
      <span className="neat">{text}</span>
      <Icon name="enter" />
    </a>
  );
};

export default Link;
